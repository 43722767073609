export default {
	cinema: {
		address: 'пл. 1 Мая, 7',
		place: null,
		phoneNumbers: ['+7 (81664) 5-17-00', '+7 (953) 90-90-345'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/kinopark_53',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CCCwaLJ-',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
